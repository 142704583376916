.services {
  padding: 1em 2em 1em 2em;
  overflow-x: hidden;
}

.services h1 {
  text-align: center;
  font-style: italic;
  text-shadow: 0em 0em 0.1em #00000098;
}

.services p {
  text-align: justify;
  font-size: 1.2em;
  margin: 1.5em 0em;
}

.services p i {
  font-size: 1.5em;
  padding: 0.5em;
  margin-right: 0.5em;
}

.services h3 {
  margin: 1em 0;
}

.cardbox .cardtxt {
  width: 80%;
  padding: 1em;
  margin: 1em 10%;
  box-shadow: 0.1em 0.1em 0.5em #000000e5;
}

.cardbox .cardtxt h3,
.cardbox .cardtxt h4 {
  text-align: center;
}

.cardbox .cardtxt p {
  padding: 0em 1em;
}

.cardbox .col-lg-6 {
  margin: 1em 0;
}

.cardbox .col-lg-6:nth-child(1) .cardtxt,
.cardbox .col-lg-6:nth-child(3) .cardtxt,
.cardbox .col-lg-6:nth-child(5) .cardtxt,
.cardbox .col-lg-6:nth-child(7) .cardtxt {
  background-color: #22526b3b;
  border-bottom-left-radius: 3em;
  border-bottom-right-radius: 1em;
  border-top-left-radius: 1em;
  border-top-right-radius: 3em;
}

.cardbox .col-lg-6:nth-child(2) .cardtxt,
.cardbox .col-lg-6:nth-child(4) .cardtxt,
.cardbox .col-lg-6:nth-child(6) .cardtxt {
  background-color: #22526b3b;
  border-bottom-left-radius: 1em;
  border-bottom-right-radius: 3em;
  border-top-left-radius: 3em;
  border-top-right-radius: 1em;
}
