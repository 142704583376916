@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600&display=swap);
* {
  margin: 0%;
  padding: 0%;
  box-sizing: border-box;
  list-style: none;
  font-family: "Poppins", sans-serif;
}

/* scroll */
::-webkit-scrollbar {
  width: 8px;
  height: 10px;
}

::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background: #1f3c56;
} /*# sourceMappingURL=scroll.css.map */

/* scroll ends */

body {
  background-color: #fff;
  margin: 0%;
  padding: 0%;
  color: #1f3c56;
}

a {
  color: #1f3c56;
  text-decoration: none;
}

a:hover {
  color: #9f3619;
  text-decoration: none;
  -webkit-text-emphasis: none;
          text-emphasis: none;
  font-weight: 500;
}

.wrapper {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

.footer {
  height: 200px;
}

.content {
  flex-grow: 1;
}

.header {
  padding: 0em 2em;
  -webkit-animation: headerani 2s linear forwards;
          animation: headerani 2s linear forwards;
}

@-webkit-keyframes headerani {
  0% {
    -webkit-transform: translateY(-100%);
            transform: translateY(-100%);
    opacity: 0;
  }
  50% {
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
    opacity: 0.5;
  }
  100% {
    -webkit-transform: translateY(0%);
            transform: translateY(0%);
    opacity: 1;
  }
}

@keyframes headerani {
  0% {
    -webkit-transform: translateY(-100%);
            transform: translateY(-100%);
    opacity: 0;
  }
  50% {
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
    opacity: 0.5;
  }
  100% {
    -webkit-transform: translateY(0%);
            transform: translateY(0%);
    opacity: 1;
  }
}

.navmenu {
  padding: 1em;
  font-size: 1.2em;
}

.menu {
  margin-left: 45%;
}

.menu li {
  padding: 1em;
  margin: auto 2em;
}

.menu li a {
  color: #1f3c56;
  text-decoration: none;
}

.menu li a:hover {
  color: #9f3619;
  text-decoration: none;
  -webkit-text-emphasis: none;
          text-emphasis: none;
  font-weight: 600;
}

.fcontent {
  padding: 1em 2em;
  width: 100%;
  border-top: 0.1em solid rgba(0, 0, 0, 0.2);
}

.fcontent h2 {
  padding: 0.5em 0em;
}

.fcontent p {
  padding: 0em 2em 0em 0em;
  font-size: 1.2em;
}

.fcontent .col-lg-5,
.fcontent .col-lg-3 {
  text-align: justify;
}

.fcontent .col-lg-3 {
  padding: 0em 5%;
}

.fcontent .col-lg-5 p {
  width: 90%;
}

.fcontent .col-lg-3 a:hover {
  text-transform: uppercase;
  letter-spacing: 0.1em;
}

.fcontent i {
  font-size: 1.2em;
}

.fcontent .col-lg-4 p:nth-child(2) i {
  margin: 0.2em 0.5em 0em 0em;
}

.fcontent .col-lg-4 p:nth-child(2) {
  text-align: center;
}

.fcontent .col-lg-4 p:nth-child(3) i,
.fcontent .col-lg-4 p:nth-child(4) i {
  margin: 0.2em 0.5em 0em 2.8em;
}

.fcontent .col-lg-4 ul li {
  display: inline-block;
  margin: 0em 1em;
  font-size: 2em;
  padding: 0.5em;
}

.home {
  overflow-x: hidden;
  margin: 0em 0em 1em 0em;
  padding: 1em;
}

.companyprofile {
  padding: 1em 0em;
}

.companyprofile h3,
.companyprofile h1 {
  text-align: center;
  width: 90%;
  padding: 1em;
  margin: 0em 5%;
}

.cardhome1 .col-lg-4,
.cardhome2 .col-lg-4 {
  padding: 3em 1em;
}

.cardhome1 .col-lg-4 .hometxt {
  background-color: #f5ededad;
  height: 25vh;
  width: 90%;
  margin-left: 5%;
  border-radius: 1em;
  -webkit-filter: drop-shadow(0.6em 0.5em 0.3em #00000094);
          filter: drop-shadow(0.6em 0.5em 0.3em #00000094);
}

.cardhome2 .col-lg-4 .hometxt {
  background-color: #f5ededad;
  height: 40vh;
  width: 90%;
  margin-left: 5%;
  border-radius: 1em;
  padding: 1em;
  -webkit-filter: drop-shadow(0.6em 0.5em 0.3em #00000094);
          filter: drop-shadow(0.6em 0.5em 0.3em #00000094);
}

.cardhome1 .col-lg-4 .hometxt h4,
.cardhome2 .col-lg-4 .hometxt h4 {
  text-align: center;
  padding: 1em 0em 0em 0em;
}

.cardhome1 .col-lg-4 .hometxt p,
.cardhome2 .col-lg-4 .hometxt p {
  text-align: justify;
  padding: 1em 2em;
}

.homeservice {
  padding: 1em 3em;
}
.homeservice p p {
  font-size: 1.2em;
  text-align: justify;
  margin-left: 5%;
  margin-top: -4.5em;
}

.homeservice p:nth-child(2) p,
.homeservice p:nth-child(3) p,
.homeservice p:nth-child(4) p {
  font-size: 1.2em;
  text-align: justify;
  width: 95%;
  margin-left: 5%;
  margin-top: -3.5em;
}

.homeservice img {
  padding: 1em;
  margin: 1em 0em;
}

.bottomTxt {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 3em 0em 0em 0em;
}

.deconstructed {
  position: relative;
  margin: auto;
  color: transparent;
  font-family: "Cambay", sans-serif;
  font-size: 4em;
  font-weight: 700;
  letter-spacing: -0.02em;
  line-height: 1.03em;
}

.deconstructed > div {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  color: #1f3c56;
  pointer-events: none;
  text-align: center;
}

.deconstructed > div:nth-child(1) {
  -webkit-mask-image: linear-gradient(black 25%, transparent 25%);
  mask-image: linear-gradient(black 25%, transparent 25%);
  -webkit-animation: deconstructed1 5s infinite;
          animation: deconstructed1 5s infinite;
}

.deconstructed > div:nth-child(2) {
  -webkit-mask-image: linear-gradient(
    transparent 25%,
    black 25%,
    black 50%,
    transparent 50%
  );
  mask-image: linear-gradient(
    transparent 25%,
    black 25%,
    black 50%,
    transparent 50%
  );
  -webkit-animation: deconstructed2 5s infinite;
          animation: deconstructed2 5s infinite;
}

.deconstructed > div:nth-child(3) {
  -webkit-mask-image: linear-gradient(
    transparent 50%,
    black 50%,
    black 75%,
    transparent 75%
  );
  mask-image: linear-gradient(
    transparent 50%,
    black 50%,
    black 75%,
    transparent 75%
  );
  -webkit-animation: deconstructed3 5s infinite;
          animation: deconstructed3 5s infinite;
}

.deconstructed > div:nth-child(4) {
  -webkit-mask-image: linear-gradient(transparent 75%, black 75%);
  mask-image: linear-gradient(transparent 75%, black 75%);
  -webkit-animation: deconstructed4 5s infinite;
          animation: deconstructed4 5s infinite;
}

@-webkit-keyframes deconstructed1 {
  0% {
    -webkit-transform: translateX(100%);
            transform: translateX(100%);
  }
  26% {
    -webkit-transform: translateX(0%);
            transform: translateX(0%);
  }
  83% {
    -webkit-transform: translateX(-0.1%);
            transform: translateX(-0.1%);
  }
  100% {
    -webkit-transform: translateX(-120%);
            transform: translateX(-120%);
  }
}

@keyframes deconstructed1 {
  0% {
    -webkit-transform: translateX(100%);
            transform: translateX(100%);
  }
  26% {
    -webkit-transform: translateX(0%);
            transform: translateX(0%);
  }
  83% {
    -webkit-transform: translateX(-0.1%);
            transform: translateX(-0.1%);
  }
  100% {
    -webkit-transform: translateX(-120%);
            transform: translateX(-120%);
  }
}

@-webkit-keyframes deconstructed2 {
  0% {
    -webkit-transform: translateX(100%);
            transform: translateX(100%);
  }
  24% {
    -webkit-transform: translateX(0.5%);
            transform: translateX(0.5%);
  }
  82% {
    -webkit-transform: translateX(-0.2%);
            transform: translateX(-0.2%);
  }
  100% {
    -webkit-transform: translateX(-125%);
            transform: translateX(-125%);
  }
}

@keyframes deconstructed2 {
  0% {
    -webkit-transform: translateX(100%);
            transform: translateX(100%);
  }
  24% {
    -webkit-transform: translateX(0.5%);
            transform: translateX(0.5%);
  }
  82% {
    -webkit-transform: translateX(-0.2%);
            transform: translateX(-0.2%);
  }
  100% {
    -webkit-transform: translateX(-125%);
            transform: translateX(-125%);
  }
}

@-webkit-keyframes deconstructed3 {
  0% {
    -webkit-transform: translateX(100%);
            transform: translateX(100%);
  }
  22% {
    -webkit-transform: translateX(0%);
            transform: translateX(0%);
  }
  81% {
    -webkit-transform: translateX(0%);
            transform: translateX(0%);
  }
  100% {
    -webkit-transform: translateX(-130%);
            transform: translateX(-130%);
  }
}

@keyframes deconstructed3 {
  0% {
    -webkit-transform: translateX(100%);
            transform: translateX(100%);
  }
  22% {
    -webkit-transform: translateX(0%);
            transform: translateX(0%);
  }
  81% {
    -webkit-transform: translateX(0%);
            transform: translateX(0%);
  }
  100% {
    -webkit-transform: translateX(-130%);
            transform: translateX(-130%);
  }
}

@-webkit-keyframes deconstructed4 {
  0% {
    -webkit-transform: translateX(100%);
            transform: translateX(100%);
  }
  20% {
    -webkit-transform: translateX(0%);
            transform: translateX(0%);
  }
  80% {
    -webkit-transform: translateX(0%);
            transform: translateX(0%);
  }
  100% {
    -webkit-transform: translateX(-135%);
            transform: translateX(-135%);
  }
}

@keyframes deconstructed4 {
  0% {
    -webkit-transform: translateX(100%);
            transform: translateX(100%);
  }
  20% {
    -webkit-transform: translateX(0%);
            transform: translateX(0%);
  }
  80% {
    -webkit-transform: translateX(0%);
            transform: translateX(0%);
  }
  100% {
    -webkit-transform: translateX(-135%);
            transform: translateX(-135%);
  }
}

@media screen and (max-width: 992px) {
  .companyprofile h3,
  .companyprofile h1 {
    text-align: center;
    width: 100%;
    margin: 0em 0em;
    font-size: 1.5em;
  }

  .cardhome1 .col-lg-4,
  .cardhome2 .col-lg-4 {
    padding: 1em 1em;
  }

  .cardhome1 .col-lg-4 .hometxt {
    background-color: #f5ededad;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    width: 100%;
    margin-left: 0%;
    border-radius: 1em;
    -webkit-filter: drop-shadow(0.6em 0.5em 0.3em #00000094);
            filter: drop-shadow(0.6em 0.5em 0.3em #00000094);
  }

  .cardhome2 .col-lg-4 .hometxt {
    background-color: #f5ededad;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    width: 100%;
    margin-left: 0%;
    border-radius: 1em;
    padding: 1em;
    -webkit-filter: drop-shadow(0.6em 0.5em 0.3em #00000094);
            filter: drop-shadow(0.6em 0.5em 0.3em #00000094);
  }

  .cardhome1 .col-lg-4 .hometxt h4,
  .cardhome2 .col-lg-4 .hometxt h4 {
    text-align: center;
    padding: 1em 1em 0em 1em;
  }

  .cardhome1 .col-lg-4 .hometxt p,
  .cardhome2 .col-lg-4 .hometxt p {
    text-align: left;
    padding: 1em 1em;
  }
  .homeservice {
    padding: 1em;
  }

  .homeservice p p {
    font-size: 1.2em;
    text-align: left;
    margin-left: 15%;
    margin-top: -12em;
  }

  .homeservice p:nth-child(2) p,
  .homeservice p:nth-child(3) p,
  .homeservice p:nth-child(4) p {
    font-size: 1.2em;
    text-align: left;
    width: 90%;
    margin-left: 15%;
    margin-top: -11em;
  }

  .homeservice img {
    padding: 5em 0em;
    margin: 1em -1em;
  }
}

.about {
  padding: 0em 2em;
  overflow-x: hidden;
}

.about h1 {
  text-transform: uppercase;
  padding: 1em 0em 0em 0em;
}

.about hr {
  background-color: cadetblue;
  height: 0.2em;
  margin: 0em 0em 2em 0em;
}

.about p {
  font-size: 1.5em;
  text-align: justify;
}

.mainAboutImage {
  background: url(/static/media/logo.543d5bdd.png);
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  width: 100%;
  height: 35vh;
  -webkit-filter: drop-shadow(1em 0.5em 0.3em #00000094);
          filter: drop-shadow(1em 0.5em 0.3em #00000094);
}

.mainAerosolImage {
  background: url(/static/media/Pro3.b49ba8a4.jpg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  width: 100%;
  height: 30vh;
  -webkit-filter: drop-shadow(1em 0.5em 0.3em #00000094);
          filter: drop-shadow(1em 0.5em 0.3em #00000094);
}

.mainHomeCareImage {
  background: url(/static/media/Pro31.219678f0.jpg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  width: 100%;
  height: 40vh;
  -webkit-filter: drop-shadow(1em 0.5em 0.3em #00000094);
          filter: drop-shadow(1em 0.5em 0.3em #00000094);
}

.mainPersonalCareImage {
  background: url(/static/media/Pro12.c1e7afe8.jpg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  width: 100%;
  height: 35vh;
  -webkit-filter: drop-shadow(1em 0.5em 0.3em #00000094);
          filter: drop-shadow(1em 0.5em 0.3em #00000094);
}

.mainPerfumeImage {
  background: url(/static/media/Pro51.b05285a9.jpg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  width: 100%;
  height: 35vh;
  -webkit-filter: drop-shadow(1em 0.5em 0.3em #00000094);
          filter: drop-shadow(1em 0.5em 0.3em #00000094);
}

.mainAboutText {
  padding: 1em 2em 1em 2em;
  text-align: justify;
}

.mainAboutTxt {
  padding: 1em 2em 1em 2em;
  text-align: justify;
}

.mainAboutTxt .row .col-lg-6 i,
.mainAboutTxt p i {
  padding: 0.5em;
  font-size: 1em;
}

.contact {
  padding: 2em;
  overflow-x: hidden;
}

.contact .row .col-lg-4 {
  text-align: center;
}

.contact .row .col-lg-4 i {
  font-size: 2.5em;
  padding: 0.5em;
  -webkit-animation: contactani 2s linear infinite;
          animation: contactani 2s linear infinite;
}

@-webkit-keyframes contactani {
  0% {
    -webkit-transform: translateY(-5%);
            transform: translateY(-5%);
  }
  50% {
    -webkit-transform: translateY(0%);
            transform: translateY(0%);
  }
  100% {
    -webkit-transform: translateY(-5%);
            transform: translateY(-5%);
  }
}

@keyframes contactani {
  0% {
    -webkit-transform: translateY(-5%);
            transform: translateY(-5%);
  }
  50% {
    -webkit-transform: translateY(0%);
            transform: translateY(0%);
  }
  100% {
    -webkit-transform: translateY(-5%);
            transform: translateY(-5%);
  }
}

.contact .row .col-lg-4 h3 {
  padding: 0em 0em 0.5em 0em;
}

.contact .row .col-lg-12 {
  padding: 1em;
}

.contact .row .col-lg-12 iframe {
  width: 100%;
  height: 65vh;
  /* margin: 0 2.5%; */
  border-radius: 1em;
  box-shadow: 0.1em 0.1em 0.5em #000000c9;
}

.contact .row .col-lg-6 .contactimg {
  background: url(/static/media/logo.543d5bdd.png);
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  width: 100%;
  height: 50vh;
  -webkit-filter: drop-shadow(1em 0.5em 0.3em #00000094);
          filter: drop-shadow(1em 0.5em 0.3em #00000094);
}

.contact .row .col-lg-6 .contacttxt {
  text-align: center;
  padding: 2em 0em;
  text-shadow: 0.1em 0.1em 0.3em #00000094;
}

.contact .row .col-lg-6 .contacttxt h2 {
  font-size: 3em;
}

.contactform {
  padding: 1em;
}

.contactform form {
  width: 80%;
  margin: 0 10%;
}

.contactform .col-lg-6,
.contactform .col-lg-12 {
  padding: 1em;
  font-size: 1.2em;
}

.contactform input,
.contactform textarea {
  width: 100%;
  margin: 0.5em 0em;
  padding: 0.5em 1em;
  border: 0.15em solid #1f3c56;
}

.contactbtn {
  padding: 1em 2em;
  margin: 0em 0.5em;
  border-radius: 0.5em;
  border: 0.1em solid #1f3c56;
  font-size: 1.2em;
  background-color: #1f3c56;
  color: white;
}

.contactbtn:hover {
  border: 0.15em solid #1f3c56;
  font-size: 1.2em;
  background-color: white;
  color: #1f3c56;
  font-weight: 600;
}

.services {
  padding: 1em 2em 1em 2em;
  overflow-x: hidden;
}

.services h1 {
  text-align: center;
  font-style: italic;
  text-shadow: 0em 0em 0.1em #00000098;
}

.services p {
  text-align: justify;
  font-size: 1.2em;
  margin: 1.5em 0em;
}

.services p i {
  font-size: 1.5em;
  padding: 0.5em;
  margin-right: 0.5em;
}

.services h3 {
  margin: 1em 0;
}

.cardbox .cardtxt {
  width: 80%;
  padding: 1em;
  margin: 1em 10%;
  box-shadow: 0.1em 0.1em 0.5em #000000e5;
}

.cardbox .cardtxt h3,
.cardbox .cardtxt h4 {
  text-align: center;
}

.cardbox .cardtxt p {
  padding: 0em 1em;
}

.cardbox .col-lg-6 {
  margin: 1em 0;
}

.cardbox .col-lg-6:nth-child(1) .cardtxt,
.cardbox .col-lg-6:nth-child(3) .cardtxt,
.cardbox .col-lg-6:nth-child(5) .cardtxt,
.cardbox .col-lg-6:nth-child(7) .cardtxt {
  background-color: #22526b3b;
  border-bottom-left-radius: 3em;
  border-bottom-right-radius: 1em;
  border-top-left-radius: 1em;
  border-top-right-radius: 3em;
}

.cardbox .col-lg-6:nth-child(2) .cardtxt,
.cardbox .col-lg-6:nth-child(4) .cardtxt,
.cardbox .col-lg-6:nth-child(6) .cardtxt {
  background-color: #22526b3b;
  border-bottom-left-radius: 1em;
  border-bottom-right-radius: 3em;
  border-top-left-radius: 3em;
  border-top-right-radius: 1em;
}

.product {
  padding: 1em;
  margin: 1em 0em;
  overflow-x: hidden;
}

.slidetxt {
  height: 80vh;
  position: relative;
  border-radius: 1em;
}

.slidetxt1 {
  background-image: url(/static/media/Pro3.b49ba8a4.jpg);
  background-position: center bottom;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 1em;
}

.slidetxt2 {
  background-image: url(/static/media/Pro30.507d4493.jpg);
  background-position: center top;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 1em;
}

.slidetxt3 {
  background-image: url(/static/media/Pro13.33e339ff.jpg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 1em;
}

.slidetxt4 {
  background-image: url(/static/media/Pro38.b77174b3.jpg);
  background-position: center top;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 1em;
}

.slidetxt5 {
  background-image: url(/static/media/Pro9.5da60a35.jpg);
  background-position: center top;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 1em;
}

.slideTxt {
  /* color: white; */
  text-align: center;
  vertical-align: middle;
  height: 80vh;
  background-color: rgba(0, 0, 0, 0.452);
  border-radius: 1em;
  /* position: absolute;
  top: 50%;
  left: 5%;
  transform: translate(0%, -50%);
  width: 90%; */
}

.slideTxt div {
  color: white;
  position: absolute;
  top: 50%;
  left: 5%;
  -webkit-transform: translate(0%, -50%);
          transform: translate(0%, -50%);
  width: 90%;
  padding: 2em 1em;
  border-radius: 1em;
}

.slideTxt div h1 {
  margin: 1em 0em;
}

.productslist {
  margin: 2em 0em;
}

.slideTxt p {
  margin: 2em 0em;
  padding: 1em 2em;
}

.slideTxt p span {
  background-color: #f5eded;
  padding: 1em 2em;
  border-radius: 0.5em;
}

.slideTxt p span:hover {
  background-color: #1e2e45;
  color: #f5eded;
  font-size: 600;
}

@media screen and (max-width: 992px) {
  .slideTxt div h1 {
    margin: 1em 0em;
    font-size: 1.5em;
  }

  .slideTxt div h3 {
    margin: 1em 0em;
    font-size: 1em;
  }

  .slideTxt p {
    margin: 2em 0em;
    padding: 1em 2em;
    font-size: 1em;
  }

  .slideTxt p span {
    background-color: #f5eded;
    padding: 1em 2em;
    border-radius: 0.5em;
  }

  .slideTxt p span:hover {
    background-color: #1e2e45;
    color: #f5eded;
    font-size: 600;
  }
}

