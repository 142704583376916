.wrapper {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

.footer {
  height: 200px;
}

.content {
  flex-grow: 1;
}
