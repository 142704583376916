@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600&display=swap");

* {
  margin: 0%;
  padding: 0%;
  box-sizing: border-box;
  list-style: none;
  font-family: "Poppins", sans-serif;
}

/* scroll */
::-webkit-scrollbar {
  width: 8px;
  height: 10px;
}

::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background: #1f3c56;
} /*# sourceMappingURL=scroll.css.map */

/* scroll ends */

body {
  background-color: #fff;
  margin: 0%;
  padding: 0%;
  color: #1f3c56;
}

a {
  color: #1f3c56;
  text-decoration: none;
}

a:hover {
  color: #9f3619;
  text-decoration: none;
  text-emphasis: none;
  font-weight: 500;
}
